import React from 'react';
import { HiMail } from 'react-icons/hi';
import { BsTelephoneFill } from 'react-icons/bs';


class Aayush extends React.Component {
    render() {
        return (<div className='container team-profile'>
            <div className='row'>
                <div className='col-md-12 profile-details'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <h1 className='profile-name'>Aayush Jain</h1>
                            <h3 style={{ textAlign: "center",color:"white" }}>Head | Information Security</h3>
                            <div className='profile-contact'>
                                <p><HiMail></HiMail> - aayush@saaasllp.com</p>
                                <p><BsTelephoneFill></BsTelephoneFill> - +91 – 9940221905</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row profile-box justify-content-center'>
                <div className='col-md-9'>
                    <h2 className='profile-heading'></h2>
                    <div className='profile-description'>
                        <ul>
                            <li>Aayush has assisted several companies to implement ISO 27701
                                (PIMS), 22301 (BCMS), SSAE 18 SOC, HIPAA, Hitrust, CSA Star, PCI
                                DSS, GDPR and CCPA compliance/certification. </li>
                            <li>He has also assisted various banks and public companies to
                                implement ISO 27001 ISMS certification.</li>
                            <li>He has also conducted regulatory information security audits,
                                cyber security audits, Aadhar audit, ERP Audit and concurrent
                                audits in information security for several banks, Prepaid wallets
                                and various insurance companies.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default Aayush;