import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(5),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>GST Compliance & Outsourcing Services</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>Assistance in obtaining various registerations under GST Law.</li>
                            <li>Our Company offers support in periodical returns for various Tax Payers which includes.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <h5 style={{ textAlign: 'left' }}><b>ITC & Vendor Compliance Management Services:</b></h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Reconciliation of ITC availed in Electronic Credit Ledger Vs. ITC auto populated in GSTR-2A.</li>
                            <li style={{ marginBottom: "7px" }}>Vendor-wise reconciliation of invoices appearing in GSTR 2A and inward register.</li>
                            <li style={{ marginBottom: "7px" }}>Analysis of eligible & in-eligible ITC as per law and those appearing in the books of accounts.</li>
                            <li style={{ marginBottom: "7px" }}>Analysis of reverse charge mechanism (RCM) transactions.</li>
                            <li style={{ marginBottom: "7px" }}>Assistance in computation of ITC reversal as per GST law.</li>
                            <li style={{ marginBottom: "7px" }}>Tracking suppliers Non-Compliance status and ITC at risk.</li>
                            <li style={{ marginBottom: "7px" }}>Analysis on distribution of common credit to branches through cross charge / ISD mode.</li>
                            <li style={{ marginBottom: "7px" }}>Regular monitoring of Suppliers GST Compliances.</li>
                            <li style={{ marginBottom: "7px" }}>Deputing On-site team to identify current gaps in ITC availment and setting up Internal control for ensuring 100% compliance in respect of ITC availed by our clients.</li>
                            <li>Our process experts helps implementation of system and Internal Controls for a addressing all compliance requirements under indirect tax statutes.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>GST Refund Services</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>We, at SAAAS, have in-depth knowledge of interconnections among various indirect tax laws, understand the intricacies in different refund options, undertake cost-benefit analysis and suggest best workable options in your peculiar case.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <h5 style={{ textAlign: 'left' }}><b>Refund of ITC on Export of Goods/Services:</b></h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Evaluating alternative modes of refund/rebates and suggesting best option.</li>
                            <li style={{ marginBottom: "7px" }}>Designing system for generation of necessary documentation for preparing refund claim.</li>
                            <li style={{ marginBottom: "7px" }}>Assistance in retrieval of supporting documents.</li>
                            <li style={{ marginBottom: "7px" }}>Preparing refund claim as per statutory requirement.</li>
                            <li style={{ marginBottom: "7px" }}>Filing refund claim with department follow up.</li>
                            <li style={{ marginBottom: "7px" }}>Making representation in case refund claim rejected.</li>
                            <li style={{ marginBottom: "7px" }}>Refund of ITC on account of Inverted duty structure.</li>
                            <li style={{ marginBottom: "7px" }}>Refund under special circumstances.</li>
                            <li style={{ marginBottom: "7px" }}>Mock-Review of refund applications filed by the company.</li>
                            <li>Review of various options available under law for dilution of Electronic credit ledger.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>GST Advisory & Review Services</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <h5 style={{ textAlign: 'left' }}><b>Transaction Advisory & Consulting Services:</b></h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Analysis on the implications of GST for a specific client/industry and optimal solution to client issue.</li>
                            <li style={{ marginBottom: "7px" }}>Hand-holding through GST advisory on regular basis.</li>
                            <li style={{ marginBottom: "7px" }}>Value added consultancy including limited reviews of activities to indicate possibility of large savings to SME's by way of credit availment, correct tax payments and availment of admissible exemptions.</li>
                            <li style={{ marginBottom: "7px" }}>Providing various options available under GST law for liquidation of ITC and Tax planning including optimisation of taxes leading to least tax incidence within four corners of the law.</li>
                            <li style={{ marginBottom: "7px" }}>Other value additive services such as classification of goods/services/tax rates and coverage in exemptions.</li>
                            <li>Analysing and providing high-level comments on various agreements, Contracts, Policies in terms of GST optimisation.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>


            


            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>GST Review Services</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Gst Compliance Review On Periodical Basis.</li>
                            <li style={{ marginBottom: "7px" }}>Gst Area Specific Reviews.</li>
                            <li style={{ marginBottom: "7px" }}>Internal Controls Over Financial Reporting From Gst Law Perspective.</li>
                            <li style={{marginBottom: "7px"}}>Gst Holistic Due-Diligence Review </li>
                            <h5 style={{ marginBottom: "7px"}}><b>GST Health Check/ Diagnostics Review:</b></h5>
                            <ol style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Review of tax positions taken by the entity vis-a-vis applicable GST laws.</li>
                            <li style={{ marginBottom: "7px" }}>Review of ITC carried forward through Transitional forms filed (TRAN 1).</li>
                            <li style={{ marginBottom: "7px" }}>Thorough review of Input tax Credits vis-a-vis eligibility and invoices pushed by vendors through their returns.</li>
                            <li style={{ marginBottom: "7px" }}>Review of Valuation and Classification methods adopted.</li>
                            <li style={{ marginBottom: "7px" }}>Monthly Return working (GSTR 1 and 3B) with respect to books of accounts with special emphasis on RCM computations.</li>
                            <li style={{ marginBottom: "7px" }}>Thorough reconciliation of books of accounts with returns filed – both turnover and taxes paid.</li>
                            <li style={{ marginBottom: "7px" }}>Reconciliation between Inward Supply register and GSTR 2A.</li>
                            <li style={{ marginBottom: "7px" }}>Reconciliation between Outward Supply register and GSTR 1.</li>
                            <li style={{ marginBottom: "7px" }}>Vendor wise non-compliance analysis with GSTINwise individual vendor list.</li>
                            <li style={{ marginBottom: "7px" }}>Job work compliances and filing of returns.</li>
                            <li style={{ marginBottom: "7px" }}>Compliance with respect to documentation, invoicing, time and place of supply as per GST Laws.</li>
                            <li>Review of ISD credits and returns for ISD.</li>
                            </ol>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography>GST- Litigation & Advocacy Support</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <h5 style={{ textAlign: 'left' }}><b>Litigation Support Services</b></h5>
                        <ol style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Preparation and filing of reply to Show Cause Notices and appearing before adjudicating Authorities, Appellate Authorities, Tribunals.</li>
                            <li style={{ marginBottom: "7px" }}>Manage the matters related to High Courts and Supreme Court with reputed attorney firms.</li>
                            <li style={{ marginBottom: "7px" }}>Advice on potential liability arising out of disputed transactions.</li>
                        </ol>
                        <h5 style={{ textAlign: 'left' }}><b>Advocacy Support Services</b></h5>
                        <ol style={{ textAlign: 'left' }}>
                        <li style={{ marginBottom: "7px" }}>Drafting of representations before GST Council, GST Policy ving, Tax Research Unit and other Government bodies.</li>
                        <li>Assistance in seeking Advance Ruling for resolving the GST queries which are complex in nature and requires clarification from the GST Advance Ruling Authority.</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <Typography>GST Annual Audit & Returns</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul tyle={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Section 35(5) of CGST Act 2017 requires a registered taxable person with turnover of more than Rs.2 crore in a year to get his books of account audited by a chartered accountant or cost accountant. The due date for submission of audited financial accounts including reconciliation statement for a financial year would be 31st December of subsequent year.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <h5 style={{ textAlign: 'left' }}><b>Assitance in Preparation and filing of Annual returns (GSTR-9) & Reconciliation statement (GSTR-9C):</b></h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Review of outward supplies declared in return to ensure that it included all outward supplies made.</li>
                            <li style={{ marginBottom: "7px" }}>Review of inward supplies declared in return to ensure that it included all inward supplies made.</li>
                            <li style={{ marginBottom: "7px" }}>Review of all deductions claimed including sales returns, abatements, exemptions.</li>
                            <li style={{ marginBottom: "7px" }}>Review of classification, rate of goods which should be according to provisions of GST Assessment of registrations obtained.</li>
                            <li style={{ marginBottom: "7px" }}>Review of input tax credits claimed on inputs, input services and capital goods.</li>
                            <li>Review of reconciliation statements are to be filed along with certified audit report.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}