import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(5),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Tax Structuring</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ul style={{textAlign:'left'}}>
              <li>With increasing complexity in the tax laws around the world, it has become essential for businesses as well as inuliduals to not only ensure that their tax policies and positions are in compliance with law, but also to make sure that they stand up to public scrutiny.</li>
              <li>More specifically, we provide advice on withholding tax matters, determining the existence of permanent establishment (‘PE’) in India for a non-resident, advising on mitigating the PE exposure, advise on foreign tax credits, filing returns for non-residents and providing tax planning opportunities in relation to any international transaction which are within the legal framework of the law.</li>
              <li>India has a complex regulatory framework that governs several areas including foreign investments, foreign exchange and the security markets. A thorough understanding of the regulatory environment, the approvals required and the compliances under this framework is a critical aspect of doing business in India.</li>
          </ul>
            <h3 style={{textAlign:'left'}}>Services:</h3>
            <ul style={{textAlign:'left'}}>
              <li style={{  marginBottom: "7px"  }}> Cross border transaction advisory and structuring to ensure tax optimization with indepth analysis of domestic regulations and DTAAs.</li>
              <li style={{  marginBottom: "7px"  }}>We advise clients on any inbound/ outbound investment into/ outside India governed by FEMA law.</li>
              <li>We focus on researching and developing innovative solutions for the international transactions which meets the client requirements.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>M&A</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ul style={{textAlign:'left'}}>
              <li> Mergers and Acquisitions and internal restructurings are increasingly becoming the norm for businesses seeking growth and efficiencies.</li>
              <li> Tax and regulatory issues play a key role in ensuring the success of M&A and restructuring activities, both in a domestic as well as cross-border context.</li>
              <li> We provide comprehensive solutions for all aspects of M&A and restructuring activities including due diligence, acquisitions, mergers, demergers, restructuring of family holdings, joint ventures and other group restructurings.</li>
              <li> Restructuring involves number of legislations and it is critical that the ideas/ solutions are tested from the perspective of each legislation before execution. For this, we use our varied experiences to generate customized solutions that enable our clients to meet their business objectives.</li>
          </ul>
            <h3 style={{textAlign:'left'}}>Services:</h3>
            <ul style={{textAlign:'left'}}>
              <li style={{  marginBottom: "7px"  }}> Advisory services on the compliance obligations of designated Regulators across jurisdictions and Transaction Structuring.</li>
              <li style={{  marginBottom: "7px"  }}> Conducting Due Diligence and Advising on the structuring and management of promoter proceeds post deal closure.</li>
              <li>Providing assisstance and handling tax compliance for either parties in the M&A dealings.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Transfer Pricing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul style={{textAlign:'left'}}>
            <li>Transfer pricing requires a strong understanding of the facts of the company as well as the industry in order to determine the appropriate arms’ length price. The Indian transfer pricing regulations requires the pricing of any international transactions between related parties to be at arm’s length and needs to be justified using the prescribed methods.</li> 
            <li>With its potential to have a significant impact on the effective tax rate, businesses must pay close attention to their transfer pricing policies, documentation and reporting. With the onset of domestic transfer pricing, these issues are not limited to multinational enterprises alone, but impact businesses in the domestic space as well.</li> 
            </ul>
            <h3 style={{textAlign:'left'}}>Services:</h3>
            <ul style={{textAlign:'left'}}>
              <li style={{  marginBottom: "7px"  }}> Framing transfer pricing policy and Conducting transfer pricing study to determine Arm's Length Price.</li>
              <li>Advisory on selection of Most Appropriate Method, Tested Party and providing assistance in complying with various laws and regulations governing the Transfer Pricing policy in India.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}