import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(5),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <div className='row justify-content-center'>
                <div className='col-md-4'>
                    <div className='card services-subtabs'>
                        <div className='card-header services-subtabs-header'>
                            <h5>Governance Risk and Compliance</h5>
                        </div>
                    </div>
                </div>
            </div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>International Organization for Standardization</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <h5 style={{ textAlign: 'left' }}><b>ISO 27001:</b></h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "10px" }}>Information security, cybersecurity, or data protection are not the things that are reserved any more for IT geeks only – this is something that concerns virtually any organization, stakeholders, investors and partners.</li>
                            <li style={{ marginBottom: "10px" }}>ISO 27001 is an integrated approach to the security of information: it requires risk assessment to be done on all of the organization’s assets – including hardware, software, documentation, people, suppliers, partners, etc., and to choose applicable controls for decreasing those risks. The Information Security Management System (ISMS) represent a set of policies, procedures, and various other controls that set the information security rules in an organization.</li>
                            <li style={{ marginBottom: "10px" }}>One of the features that differentiate this standard from other information security frameworks/standards is that an organization can become certified by an accredited certification body and will therefore be able to prove its compliance to its customers, partners, owners, and other stakeholders.</li>
                        </ul>
                        <h5 style={{ textAlign: 'left' }}><b>ISO/IEC 27701:</b></h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "10px" }}>ISO/IEC 27701 is a privacy extension to ISO/IEC 27001 Information Security Management and ISO/IEC 27002 Security Controls. An international management system standard, it provides guidance on the protection of privacy, including how organizations should manage personal information, and assists in demonstrating compliance with privacy regulations around the world.</li>
                        </ul>
                        <h5 style={{ textAlign: 'left' }}><b>ISO 22301:</b></h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "10px" }}>ISO 22301 is a standard for a management system to adhere to for business disruptions. The requirements aim to help companies reduce the likelihood of interruptions while also preparing for and responding to them when they arise</li>
                            <li style={{ marginBottom: "10px" }}>The full name of this standard is ISO 22301:2019 Security and resilience – Business continuity management systems – Requirements. It is an international standard published by the International Organization for Standardization (ISO), and it describes how to manage business continuity in an organization.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>SOC (Service Organization Control)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>SOC stands for System and Organization Controls (previously Service Organization Controls) and is an audit of a companies controls that are in place to help ensure the Security, Availability, Processing Integrity, Confidentiality and Privacy of their customers data. The SOC control standards were created and overseen by the American Institute of Certified Public Accountants (AICPA). SOC audits come in many types including SOC 1 & SOC 2.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Our Approach:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}><b>Gap Analysis</b> – Gap Analysis with the existing practice vis a vis requirement from the SSAE 18 standard</li>
                            <li style={{ marginBottom: "7px" }}><b>Preparation of Controls</b> – Design of controls as per the companies practices and requirements from the standard.</li>
                            <li style={{ marginBottom: "7px" }}><b>Preparation of Document</b> – Creating policies, procedures and other documentation.</li>
                            <li style={{ marginBottom: "7px" }}><b>Implementation Support </b>– Standby support for implementing controls.</li>
                            <li style={{ marginBottom: "7px" }}><b>Audits & Attestation Support</b> - Liaising with the CPA and support during CPA audit.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Data Privacy Consulting</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>Most organizations are subject to multiple privacy laws on global, federal, state, and industry-specific levels. These laws define Personally Identifiable Information (PII), consumer rights, and the actions business must take in collecting & notification, response to consumer requests, use of PII in marketing, or the sales of consumer data. Organizations must maintain a defensible privacy practice to avoid violations where auditors, courts, regulators, and class action attorneys may be concerned. Privacy readiness involves reviewing your legal exposure, vendor contracts, privacy policy, responsiveness to consumer requests, and employee training. Defensible privacy includes maintaining and monitoring policies, contracts, procedures, clear outward-facing documentation, and managing privacy audits.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Our Approach:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}><b>Gap Analysis</b> - Assess your organization vis-à-vis the Data Privacy Law requirements to identify areas that need to be addressed.</li>
                            <li style={{ marginBottom: "7px" }}><b>Privacy Impact Assessment</b> - Conduct a comprehensive Privacy Impact Assessment to identify weak areas and loopholes that could impact the data privacy. Strategize appropriate Risk Treatment measures.</li>
                            <li style={{ marginBottom: "7px" }}><b>Documentation</b> - We will help you build and rollout effective policies and procedures for your organization, pertaining to Data Privacy Compliance.</li>
                            <li style={{ marginBottom: "7px" }}><b>Readiness Audit</b> – Conducting a re-assessment of your setup and ensure all measures are implemented.</li>
                            <li style={{ marginBottom: "7px" }}><b>Certification</b> - Once all controls are confirmed to be in place, we will be issuing a legally admissible "Data Privacy Compliance" Certificate for your organization.</li>
                            <li><b>Continual Support</b> - If required we can extend our continual support by offering you Managed Compliance Services to help your organization stay compliant.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>





            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>HIPAA Compliance Consulting and Audit</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>The Health Insurance Portability and Accountability Act (HIPAA) sets the standard for sensitive patient data protection. Companies that deal with protected health information (PHI) must have physical, network, and process security measures in place and follow them to ensure HIPAA Compliance. Covered entities (anyone providing treatment, payment, and operations in healthcare) and business associates (anyone who has access to patient information and provides support in treatment, payment, or operations) must meet HIPAA Compliance. Other entities, such as subcontractors and any other related business associates must also be in compliance.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Our Approach:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}><b>Gap Analysis</b> - Assess your organization vis-à-vis the HIPAA standard requirements to identify areas that need to be addressed.</li>
                            <li style={{ marginBottom: "7px" }}><b>Risk Assessment & Treatment</b> - Conduct a comprehensive Risk Assessment to identify weak areas and loopholes that could impact the business-critical assets of your organization. Strategize appropriate Risk Treatment measures.</li>
                            <li style={{ marginBottom: "7px" }}><b>Documentation</b> - We will help you build and rollout effective policies and procedures for your organization, pertaining to HIPAA Compliance.</li>
                            <li style={{ marginBottom: "7px" }}><b>HIPAA Compliance Audit</b> – Conducting a Pre-assessment of your setup and ensure all measures are implemented.</li>
                            <li style={{ marginBottom: "7px" }}><b>Certification</b> - Once all controls are confirmed to be in place, we will be issuing a legally admissible "HIPAA Compliance" Certificate for your organization.</li>
                            <li style={{ marginBottom: "7px" }}><b>Continual Support</b> - If required we can extend our continual support by offering you Managed Compliance Services to help your organization stay compliant.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography>IS Audit </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li li style={{ marginBottom: "7px" }}>Information system Audit is an audit of Information Technology environment to assess the security of the system and framework set-up by the organisation to run the business and non-business operation.</li>
                        </ul>
                        <h5 style={{ textAlign: 'left' }}><b>RBI:</b></h5>
                        <ol style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>RBI has issued various circulars to mandate Banks, Small finance banks, Cooperation banks, non-banking financial companies, Housing finance companies, and entities regulated by payment and settlement systems act for compliance with Information system/technology audits.</li>
                            <li style={{ marginBottom: "7px" }}>Various audits mandated by RBI are IS Audit, Cyber security audit, PPI System audit, White label ATM system audit, Data Localization Audit.</li>
                        </ol>
                        <h5 style={{ textAlign: 'left' }}><b>IRDAI</b></h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>IRDAI has mandated every Insurers to carry out information system audit.</li>
                        </ul>
                        <h5 style={{ textAlign: 'left' }}><b>SEBI</b></h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>SEBI has mandated its regulated entities like Depositories, Intermediaries, Mutual funds etc., to carry out information system audit.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Our Approach:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "10px" }}>We carryout IS audit based on the globally accepted standard guidelines like ISO, OSWAP, NIST, and regulated bodies (RBI, SEBI, IRDAI, CERT-In etc) of the entity. We also consider industry specific standards like PCI DSS, HIPPA, SSAE etc based in the entity for which audit is carried out.</li>
                            <li>The broad scope elements of the IS Audit (ITGC & ITAC) are:
                                <ol>
                                    <li>IT Governance Structure Review</li>
                                    <li>Review of policies and procedures</li>
                                    <li>Compliance with regulated bodies (RBI, SEBI, IRDAI, CERT-IN etc) guidelines</li>
                                    <li>HR security</li>
                                    <li>User Access Management</li>
                                    <li>Logical Access Control</li>
                                    <li>Change Management</li>
                                    <li>Log Management</li>
                                    <li>Back-up & Recovery Management</li>
                                    <li>Network Security</li>
                                    <li>End point security</li>
                                    <li>Incident Management Review</li>
                                    <li>Physical and environmental security</li>
                                    <li>Security infrastructure review</li>
                                    <li>Asset management controls / Software license controls</li>
                                    <li>BCP and DR review</li>
                                    <li>Third Party Vendor Review</li>
                                    <li>Mobile Device management</li>
                                    <li>Vulnerability Assessment and Penetration testing</li>
                                    <li>Review of previous audit closure status</li>
                                </ol>
                            </li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <Typography>Migration Audit</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Migration Audit is to provide assurance of data migration from one application or version to another application or version. It ensures Integrity, control, continuity & effectiveness.</li>
                            <li>We carryout migration audit for both financial and non-financial data.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>






            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                    <Typography>Process Review Audit</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>We provide various System based process review audits for organisations to ensure effectiveness of Data Validation, Integrity, User interface, business solutions implemented within the IT infrastructure and comply with various regulations issued by governing bodies.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Business application review like Loan origination system, gold loan application, Customer On-boarding application, ATM reconciliation system, ERPs etc. </li>
                            <li style={{ marginBottom: "7px" }}>System-based compliance with Income recognition and Asset classification norms issued by RBI for Banks, SFBs & NBFCs. </li>
                            <li style={{ marginBottom: "7px" }}>System related risk of business process for compliance with ICFR. </li>
                            <li style={{ marginBottom: "7px" }}>Interest maintenance within CBS.</li>
                            <li style={{ marginBottom: "7px" }}>Reconciliation review of various payment channels like ATM, POS, NPCI, UPI, Gift cards etc. </li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className='row justify-content-center'>
                <div className='col-md-4'>
                    <div className='card services-subtabs'>
                        <div className='card-header services-subtabs-header'>
                            <h5>Cloud Security Assessment (CSA)</h5>
                        </div>
                    </div>
                </div>
            </div>
            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                    <Typography>Cloud Security Assessment (CSA)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li  style={{ marginBottom: "7px" }}>Cloud applications are no longer optional. They lower your costs, increase agility and become a standard piece of every modern digital infrastructure. Unfortunately, the cloud also creates a wide range of new security risks that leave your critical assets and workloads exposed to criminals. To drive a secure digital transformation, you must find a way to map, measure, and manage these risks.</li>
                            <li  style={{ marginBottom: "7px" }}>Our Cloud Security Assessment (CSA) services help you do just that. Our CSA services protect your data and workloads in the cloud, ensure reliable access to your cloud assets, and help you dive into a secure, confident digital transformation.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Our Approach:</h3>
                        <p style={{ textAlign: 'left' }}>Our assessments give you a practical, proven, and reliable way to measure your cloud risk against leading security industry standards. We will provide you a clear picture of what cloud-based risks you carry and how to reduce them systematically. Each of our assessments covers every major cloud security threat, and:</p>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Follow a proven step-by-step approach that exceeds industry standards.</li>
                            <li style={{ marginBottom: "7px" }}>Built and delivered by experienced cloud security experts.</li>
                            <li style={{ marginBottom: "7px" }}>Deploy non-invasive processes that preserve business operations.</li>
                            <li style={{ marginBottom: "7px" }}>Provide executive-ready reports and precise technical recommendations</li>
                            <li style={{ marginBottom: "40px" }}>Give you a cloud security roadmap that you can quickly bring to life.</li>
                            </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className='row justify-content-center'>
                <div className='col-md-5'>
                    <div className='card services-subtabs'>
                        <div className='card-header services-subtabs-header'>
                            <h5>Vulnerability Assessment & Penetration Testing (VAPT)</h5>
                        </div>
                    </div>
                </div>
            </div>
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                    <Typography>Vulnerability Assessment & Penetration Testing (VAPT)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Periodic Vulnerability Assessment & Penetration Testing (VAPT) are now mandated by regulatory directives. contractual agreements, standards, and frameworks and will provide the organization with a strong security posture.</li>
                            <li style={{ marginBottom: "7px" }}>Periodic Vulnerability Assessment & Penetration Testing (VAPT) are now mandated by regulatory directives, contractual agreements, standards, and frameworks.</li>
                            <li style={{ marginBottom: "7px" }}>Vulnerability Assessment focuses on creating a list of identified vulnerabilities and establishing a plan to remediate findings. The focus of a Penetration Test is to demonstrate success against the testing objective like breaching an organization’s border security controls, gaining administrative rights to a key system etc.</li>
                            <li style={{ marginBottom: "7px" }}>We can manage an organisation specific VAPT requirements and helps to mitigate security risks proactively.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>IT Infrastructure (Servers, Routers, Switches, Firewalls etc).</li>
                            <li style={{ marginBottom: "7px" }}>Web Application (Application Security Assessment).</li>
                            <li style={{ marginBottom: "7px" }}>Mobile Application (Application Security Assessment).</li>
                            <li style={{ marginBottom: "7px" }}>APIs (API Security Testing).</li>
                            </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}