import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Aayush from './Profiles/Aayush';
import Akash from './Profiles/Akash';
import Ajay from './Profiles/Ajay';
import Shahill from './Profiles/Shahill';
import Shravan from './Profiles/Shravan';

class Team extends React.Component {

    render() {
        return (
            <div className='container' style={{ 'marginTop': '50px', 'marginBottom': '50px' }}>
                <Tabs defaultActiveKey="shravan" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="shravan" title="Shravan Gehlot" style={{ 'color': '#1D2951 !important' }}>
                        <Shravan />
                    </Tab>
                    <Tab eventKey="akash" title="Akash Jain">
                        <Akash />
                    </Tab>
                    <Tab eventKey="ajay" title="Ajay Mehta">
                        <Ajay />
                    </Tab>
                    <Tab eventKey="aayush" title="Aayush Jain">
                        <Aayush />
                    </Tab>
                    <Tab eventKey="shahill" title="Shahill Kumar">
                        <Shahill />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}
export default Team;