import React from 'react';
import { HiMail } from 'react-icons/hi';
import { BsTelephoneFill } from 'react-icons/bs';


class Akash extends React.Component {
    render() {
        return (<div className='container team-profile'>
            <div className='row'>
                <div className='col-md-12 profile-details'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <h1 className='profile-name'>Akash Jain</h1>
                            <h3 style={{ textAlign: "center",color:'white' }}>Head  | International Tax and Structuring</h3>
                            <div className='profile-contact'>
                                <p><HiMail></HiMail> - akash@saaasllp.com</p>
                                <p><BsTelephoneFill></BsTelephoneFill> - +91 - 9840750013</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row profile-box justify-content-center'>
                <div className='col-md-9'>
                    <h2 className='profile-heading'></h2>
                    <div className='profile-description'>
                        <ul>
                            <li>Chartered Accountant with a forte in the field of International and
                                Domestic Taxation with Cross border Transaction Advisory services.</li>
                            <li>He specializes in Cross Border Mergers & Acquisition, FEMA
                                compliances, Corporate Tax Structuring, Due Diligence & Transfer
                                Pricing assignments. </li>
                            <li>Various Industries explored including; IT & ITES services, Real estates,
                                Wholesale and retail trade, Automobile.</li>
                            <li>He is also a Proactive Facilitator with more than 10 webinars on
                                International & Domestic Taxation topics at various forums.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default Akash;