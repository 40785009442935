import React from 'react';
import ProcessAuditsSubTabs from './Process_Audits_SubTabs'

class Process_Audits extends React.Component {

    render() {
        return (<div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card services'>
                            <div className='card-header services-header'>
                                <h2>Process/Audits</h2>
                            </div>
                            <div className='card-body services-body'>
                                <p>Auditing Services means examination or inspection of business records by an auditor, including checking and verifying accounts, statements, or other representation of the financial position or regulatory compliance of the auditee.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12' style={{marginBottom:"100px"}}>
                       <ProcessAuditsSubTabs/>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default Process_Audits;