import React from 'react';
import DomesticTaxationSubTasks from './DomesticTaxationSubTabs'

class DomesticTaxation extends React.Component {

    render() {
        return (<div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card services'>
                            <div className='card-header services-header'>
                                <h2>Domestic Taxation</h2>
                            </div>
                            <div className='card-body services-body'>
                                <p>Domestic taxes are taxes chargeable on profits or gains under the laws of the country in which an entity is established or a personis a resident.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12' style={{marginBottom:"100px"}}>
                       <DomesticTaxationSubTasks/>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default DomesticTaxation;