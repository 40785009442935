import React from 'react';
import {AiOutlinePhone } from 'react-icons/ai';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BiEnvelope } from 'react-icons/bi';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    return (<div className='container-fluid footer'>
      <div className='row'>
      <div className='col-md-6'>
          <div className="row">
            <div className='container-fluid'>
            <div className='row'>
            <h2 style={{paddingTop:'70px'}}> SAAAS</h2>
            </div>
            <div className='row'>
            <p className="menu">
              <span> <Link to='/' style={{color:"white"}}>Home</Link></span> | <span><Link to="/about" style={{color:"white"}}>About Us</Link></span> |
               <span><Link to='/team' style={{color:"white"}}> Team</Link></span> 
            </p>
            {/* <p className="name"> SAAAS &copy; 2022</p> */}
            </div>
            {/* <div className='row'>
             <div className='col-md-12 footer-icons'>
               <span> <AiOutlineInstagram size={20} />   </span><span><FiLinkedin size={20}/>   </span><span><FiTwitter size={20}/></span><span><FiFacebook size={20}/></span>
             </div>
            </div> */}
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='container-fluid'>
            <div className='row'>
            <h4>Contact Us</h4>
            <div className='col-md-12'>
              <HiOutlineLocationMarker size={20}/><span> Hardevi Chambers, Office No: 75, <br/>2nd Floor, Pantheon Road, <br/> Egmore, Chennai - 600008,<br/>Tamilnadu,  India</span>
            </div>
            </div>
            <div className='row'>
            <div>
              <AiOutlinePhone size={20}/><span> (+91) 044 47430951</span>
            </div>
            </div>
            <div className='row'>
            <div className='col-md-12'>
              <BiEnvelope size={20}/><span> office@saaasllp.com</span>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>);
  }
}

export default Footer;