import React from 'react';


class AboutUs extends React.Component {
    render() {
        return (<div>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-8'>
                        <div className='card about-card'>
                            <div className='card-header about-card-header'>
                                <h2>About Us</h2>
                            </div>
                            <div className='card-body about-card-body'>
                                <p className="text-center" style={{ padding: '30px' }}>SAAAS is an ideation of childhood friends who have a vision to provide business solutions across various industries in the areas of Process, Taxation and Information Technology. SAAAS & Co is a India based firm which is highly concerned with the quality of services, approach, value, commitment, and behaviour towards fulfilment of client’s objective. With a strong belief in individuality of each client, we always endeavour to provide personalized professional services with commitment, while adhering to the best of solutions and practices with utmost dynamism and with use of advanced technology. Our solution driven approach with our large team / network of professionals has helped us serve entities from start-ups to large corporates in India and International.</p>
                                <div style={{ paddingLeft: '40px' }}>
                                <h3 style={{ textAlign: 'left',color:"#1D2951" }}><b>Core Values:</b></h3>
                                <ul style={{ textAlign: 'left' }}>
                                    <li style={{ marginBottom: "7px" }}>Excellence</li>
                                    <li style={{ marginBottom: "7px" }}>Discipline</li>
                                    <li style={{ marginBottom: "7px" }}>Entrepreneurial Spirit</li>
                                    <li style={{ marginBottom: "7px" }}>Gratitude</li>
                                    <li style={{ marginBottom: "7px" }}>Fidelity</li>
                                </ul>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}
export default AboutUs;