import React from 'react';
import GoodsServiceTaxSubTasks from './GoodsServiceTaxSubTabs'

class GoodsServiceTax extends React.Component {

    render() {
        return (<div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card services'>
                            <div className='card-header services-header'>
                                <h2>Goods & Service Tax</h2>
                            </div>
                            <div className='card-body services-body'>
                                <p>The goods and services tax (GST) is a value-added tax (VAT) levied on most goods and services sold for domestic consumption. The GST is paid by consumers, but it is remitted to the government by the businesses selling the goods and services.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12' style={{marginBottom:"100px"}}>
                       <GoodsServiceTaxSubTasks/>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default GoodsServiceTax;