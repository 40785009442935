import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(5),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Compliance services</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>  We assist corporates to deal with the various challenges arising from a complex tax environment. We assist companies across a spectrum of matters relating to applicability of withholding tax, deductibility of any expenditure, availability of exemption or enhanced deduction or tax holiday on any particular source of income or expenditure or investment.</li>
                            <li>We also undertake corporate tax compliances ranging from advance tax computations, preparation and filing of tax returns, assistance with documentation requirements to support the planning and positions adopted for corporate and withholding tax matters.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Providing end to end tax regulatory compliance and assisstant services.</li>
                            <li>Ensuring meeting dead lines and filing various forms and returns to the regulatory authorities including Income Tax Department, Ministry of Companies Act, Reserve Bank of India etc.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Consulting service</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>We assist corporates to deal with the various challenges arising from a complex tax environment.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}> Providing advisory services to attain tax efficiency in an compliant manner.</li>
                            <li>Review of documentation to ensure that the position adopted by the clients are appropriate.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Litigations</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>With growing costs of tax litigation, there is an urgent need for adopting a new mind-set, focused on holistic tax controversy management, rather than relying solely on traditional litigation which is just one of the ways to resolving tax controversies.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Services:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}> Assistance in representation before the tax authorities in relation to various direct tax matters.</li>
                            <li>Liasoning with the tax authorities for various registrations and exemption certificates applications.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}