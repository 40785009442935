import { Component } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdContactSupport,MdOutlineMiscellaneousServices } from 'react-icons/md';
import {AiFillHome} from 'react-icons/ai';
import {RiTeamFill} from 'react-icons/ri';
import {BsInfoCircleFill} from 'react-icons/bs';

class NavBar extends Component {
  render() {
    return (<div>
      <Navbar collapseOnSelect expand="lg" style={{'boxShadow': '0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22)','backgroundColor': '#1D2951'}}>
        <Container>
          <Navbar.Brand as={Link} to='/'>SAAAS</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to='/' className='menu'><span><AiFillHome/> Home</span></Nav.Link>
              <Nav.Link as={Link} to='/about' className='menu'><span><BsInfoCircleFill/> About us</span></Nav.Link>
              <Nav.Link as={Link} to='/team' className='menu'><span><RiTeamFill/> Team</span></Nav.Link>
             <NavDropdown title={<span className='menu'><MdOutlineMiscellaneousServices/> Services</span>} id="collasible-nav-dropdown" renderMenuOnMount={true} >
                <NavDropdown.Item as={Link} to="/service/InformationTechnology" className='text-wrap'>Information Technology</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/service/Process_Audits" className='text-wrap' >Process/Audits</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/service/InternationalTaxation" className='text-wrap'>International Taxation</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/service/DomesticTaxation" className='text-wrap'>Domestic Taxation</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/service/Goods&ServiceTax" className='text-wrap'>Goods & Service Tax</NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/service/Customs&FTP" className='text-wrap'>Customs & FTP</NavDropdown.Item> */}
              </NavDropdown>
            </Nav>
            <Nav>
              <Nav.Link as={Link} to='/contactUs' className='menu'>Contact us <MdContactSupport /></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
    )
  }
}

export default NavBar;