import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './components/Home.js';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Team from './components/Team';
import Akash from './components/Profiles/Akash';
import Aayush from './components/Profiles/Aayush';
import Ajay from './components/Profiles/Ajay';
import Shahill from './components/Profiles/Shahill';
import Shravan from './components/Profiles/Shravan';
import InformationTechnology from './components/services/InformationTechnology';
import ContactUs from './components/ContactUs';
import InternationalTaxation from './components/services/InternationalTaxation';
import DomesticTaxation from './components/services/DomesticTaxation';
import GoodsServiceTax from './components/services/GoodsServiceTax';
import Process_Audits from './components/services/Process_Audits';
import AboutUs from './components/AboutUs';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <NavBar></NavBar>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path='/home' element={<Home />} />
          <Route path='/team' element={<Team />} />
          <Route path='/team/Akash' element={<Akash />} />
          <Route path='/team/Aayush' element={<Aayush />} />
          <Route path='/team/Ajay' element={<Ajay />} />
          <Route path='/team/Shahill' element={<Shahill />} />
          <Route path='/team/Shravan' element={<Shravan />} />
          <Route path='/service/InformationTechnology' element={<InformationTechnology />} />
          <Route path='/service/Process_Audits' element={<Process_Audits />} />
          <Route path='/service/InternationalTaxation' element={<InternationalTaxation />} />
          <Route path='/service/DomesticTaxation' element={<DomesticTaxation />} />
          <Route path='/service/Goods&ServiceTax' element={<GoodsServiceTax />} />
          {/* <Route path='/service/Customs&FTP' element={<InformationTechnology />} /> */}
          <Route path='/contactUs' element={<ContactUs/>}/>
          <Route path='/about' element={<AboutUs/>}/>
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
