import React from 'react';
import Service1 from './images/ServiceCards/Service1.jpg';
import Service2 from './images/ServiceCards/Service2.jpg';
import Service3 from './images/ServiceCards/Service3.jpg';
import Service4 from './images/ServiceCards/Service4.jpg';
import Service5 from './images/ServiceCards/Service5.jpg';
// import Service6 from './images/ServiceCards/Service6.jpg';
// import Service7 from './images/ServiceCards/Service7.jpg';
import { Link } from 'react-router-dom';


class ServiceCards extends React.Component {
    render() {
        return (<div className='container' style={{ marginTop: '40px' }} id="service-card-id">
            <div className='col-md-12'>
                <h1 className="text-center" style={{'color':'#1D2951'}}> Services</h1>
            </div>
            <div className="container">
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='card service-card'>
                            <div className='card-body'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                        <img className="service-card-img" src={Service1} alt="Service1" />
                                        </div>
                                        <div className='col-md-6'>
                                            <h3 style={{color:'#1D2951',marginTop:'20px'}}>Information Technology</h3>
                                            <p>Information technology is based on computer technology, business and technology services.We provide services related to Governance Risk and Compliance, Vulnerability Assessment & Penetration Testing etc..... </p> 
                                            <br/>
                                           <Link to='/service/InformationTechnology'> <button className='btn btn-primary'>Know more</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='card service-card'>
                            <div className='card-body'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                        <img className="service-card-img" src={Service4} alt="Service2" />
                                        </div>
                                        <div className='col-md-6'>
                                            <h3 style={{color:'#1D2951',marginTop:'20px'}}>Process/Audits</h3>
                                            <p>Auditing Services means examination or inspection of business records by an auditor, including checking..... </p> 
                                            <br/>
                                           <Link to='/service/Process_Audits'> <button className='btn btn-primary'>Know more</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <div className='card service-card'>
                            <div className='card-body'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                        <img className="service-card-img" src={Service3} alt="Service3" />
                                        </div>
                                        <div className='col-md-6'>
                                            <h3 style={{color:'#1D2951',marginTop:'20px'}}>International Taxation</h3>
                                            <p>International taxation is the study or determination of tax on a person or business subject to the tax laws of different countries or the international aspects of an individual country’s tax laws as the case may be..... </p> 
                                            <br/>
                                           <Link to='/service/InternationalTaxation'> <button className='btn btn-primary'>Know more</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='card service-card'>
                            <div className='card-body'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                        <img className="service-card-img" src={Service2} alt="Service4" />
                                        </div>
                                        <div className='col-md-6'>
                                            <h3 style={{color:'#1D2951',marginTop:'20px'}}>Domestic Taxation</h3>
                                            <p>Domestic taxes are taxes chargeable on profits or gains under the laws of the country in which an entity is established or a personis a resident.... </p> 
                                            <br/>
                                           <Link to='/service/DomesticTaxation'> <button className='btn btn-primary'>Know more</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <div className='card service-card'>
                            <div className='card-body'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                        <img className="service-card-img" src={Service5} alt="Service5" />
                                        </div>
                                        <div className='col-md-6'>
                                            <h3 style={{color:'#1D2951',marginTop:'20px'}}>Goods & Service Tax</h3>
                                            <p>The goods and services tax (GST) is a value-added tax (VAT) levied on most goods and services sold for domestic consumption. The GST is paid by consumers, but it is remitted to the government by the businesses selling the goods and services..... </p> 
                                            <br/>
                                           <Link to='/service/Goods&ServiceTax'> <button className='btn btn-primary'>Know more</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <div className='card service-card'>
                            <div className='card-body'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                        <img className="service-card-img" src={Service7} alt="Service7" />
                                        </div>
                                        <div className='col-md-6'>
                                            <h3 style={{color:'#1D2951',marginTop:'20px'}}>Customs & FTP</h3>
                                            <p>Business models constantly evolve and businesses grow, acquire, merge, sell or metamorph into such other form or transaction as may be warranted. Each of these options have their own set of challenges and .... </p> 
                                            <br/>
                                           <Link to='/service/Customs&FTP'> <button className='btn btn-primary'>Know more</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div> */}
            </div>
        </div>);
    }
}

export default ServiceCards;