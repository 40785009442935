import React from 'react';
import InformationTechnologySubTabs from './InformationTechnologySubTabs'

class InformationTechnology extends React.Component {

    render() {
        return (<div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card services'>
                            <div className='card-header services-header'>
                                <h2>Information Technology</h2>
                            </div>
                            <div className='card-body services-body'>
                                <p>Information technology is based on computer technology, business and technology services.We provide services related to Governance Risk and Compliance, Vulnerability Assessment & Penetration Testing etc. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12' style={{marginBottom:"100px"}}>
                       <InformationTechnologySubTabs/>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default InformationTechnology;