import React from 'react';
import InternationalTaxationSubTabs from './InternationalTaxationSubTabs'

class InternationalTaxation extends React.Component {

    render() {
        return (<div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card services'>
                            <div className='card-header services-header'>
                                <h2>International Taxation</h2>
                            </div>
                            <div className='card-body services-body'>
                                <p>International taxation is the study or determination of tax on a person or business subject to the tax laws of different countries or the international aspects of an individual country’s tax laws as the case may be.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12' style={{marginBottom:"100px"}}>
                       <InternationalTaxationSubTabs/>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default InternationalTaxation;