import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(5),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Sarbanes Oxley Act Audit and Compliance</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>The Sarbanes-Oxley Act of 2002 (SOX) was passed by the United States Congress to protect the public from fraudulent or erroneous practices by corporations or other business entities. All organizations should behave ethically and limit access to financial data. It also has the added benefit of helping organizations keep sensitive data safe from insider threats, cyber-attacks, and security breaches. Meeting SOX compliance requirements is not only a legal obligation but good business practice.</li>
                            <li style={{ marginBottom: "7px" }}>The Goal of SOX is “to protect investors by improving the accuracy and reliability of corporate disclosures.”</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>About SOX:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>It is applicable to all publicly-traded companies, wholly-owned subsidiaries, and foreign companies that are publicly traded and do business in the United States must comply with SOX. Also, it applies to accounting firms that audit public companies.</li>
                            <li style={{ marginBottom: "7px" }}>The cooperation of IT departments is critical for SOX compliance because their efforts are necessary to ensure financial data security and financial record availability.</li>
                            <li style={{ marginBottom: "7px" }}>A SOX compliance audit is a mandated yearly assessment of how well your company is managing its internal controls and the results are made available to shareholders.</li>
                            <li style={{ marginBottom: "7px" }}>SOX approach applies a top-down risk-based methodology that helps clients focus on the right risks and maximize efficiencies. We provide a full range of SOX advisory services, which address your business and compliance needs.</li>
                            </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Forensic Audit</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li>A forensic audit is an examination of a company’s financial records to derive evidence which can be used in a court of law or legal proceeding. Forensic Auditing has established itself as dynamic and strategic tool in combating corruption, financial crimes and frauds through investigations and resolving allegations of fraud and embezzlement.</li>
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>About Forensics:</h3>
                        <p style={{ textAlign: 'left' }}><b>Methodology: </b>An auditor can follow a nine-step methodology for fact finding in case of forensic audit engagements: -</p>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Accept the forensic audit engagement.</li>
                            <li style={{ marginBottom: "7px" }}>Evaluate the allegations or suspicions.</li>
                            <li style={{ marginBottom: "7px" }}>Conduct due diligence background notes.</li>
                            <li style={{ marginBottom: "7px" }}>Complete the preliminary stage of investigation.</li>
                            <li style={{ marginBottom: "7px" }}>Check the prediction assuming that there will be a litigation.</li>
                            <li style={{ marginBottom: "7px" }}>Begin with external investigation.</li>
                            <li style={{ marginBottom: "7px" }}>Gathering the required proofs and evidences.</li>
                            <li style={{ marginBottom: "7px" }}>Preparing report on findings.</li>
                            <li style={{ marginBottom: "7px" }}>Court proceedings.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Internal Audit and Process review</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>An internal audit may be used to assess an organization’s performance or the execution of a process against a number of standards, policies, metrics, or regulations. These audits may include examining a business’s internal controls around corporate governance, accounting, financial reporting, and IT general controls.</li>
                           
                        </ul>
                        <h3 style={{ textAlign: 'left' }}>Types:</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}><b>Compliance Audits</b>:Evaluate compliance with applicable laws, regulations, policies and procedures. Some of these regulations may have a significant impact on the company’s financial well-being. Failure to comply with some laws, such as the Foreign Corrupt Practices Act (FCPA) or General Data Protection Regulation (GDPR), may result in millions of dollars in fines or preclude a company from doing business in certain jurisdictions. </li>
                            <li style={{ marginBottom: "7px" }}><b>Environmental Audit</b>:Assess the impact of a company’s operations on the environment. They may also assess the company’s compliance with environmental laws and regulations.</li>
                            <li style={{ marginBottom: "7px" }}><b>Operational Audits</b>:Assess the organization’s control mechanisms for their overall efficiency and reliability.</li>
                            <li style={{ marginBottom: "7px" }}><b>Performance Audits</b>:Evaluate whether the organization is meeting the metrics set by management in order to achieve the goals and objectives set forth by the Board of Directors.</li>
                             </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>


            


            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>Internal Financial Control (IFC)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>The Companies Act, 1956 has been replaced by the Companies Act, 2013. The new Act has cast many new reporting requirements on the statutory auditors of companies. One of these requirements relate to ‘Reporting on Internal Financial Controls’ under Section 143(3)(i) of the Companies Act, 2013 which requires the auditor to state in his report whether the company has adequate internal financial controls system in place and the operating effectiveness of such controls.</li>
                            <li style={{ marginBottom: "7px" }}>The section has cast onerous responsibilities on the statutory auditors because various audit principles and audit procedures for audit of financial statements as per the auditing standards issued by the Institute of Chartered Accountants of India (ICAI) do not cover situations where the auditors are required to express opinion of effectiveness of internal controls.</li>
                        </ul>
                        <h5 style={{ textAlign: 'left' }}>Difference between IFC and Internal Audit:</h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Internal Financial Control (IFC) can help an internal auditor in understanding the process and evaluating risk by providing a 360’degree view about the policies, procedures and operations of a company. The internal auditor can then validate the process and assure risk mitigation to the audit committee.</li>
                          
                        </ul>
                        <h5 style={{ textAlign: 'left' }}>RCM in IFC:</h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>The Risk Control Matrix (RCM) is an essential element of the system that enables clients to perform a "data-driven" analysis for a given process, organization, IT system, project/event or custom entity.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography>Banking Compliance</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                      <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Compliance in Banks is designed to provide an overview of the internal guidelines, statutory and regulatory stipulations in force that regulate the financial environment with special reference to banks operating in India.</li>
                        </ul>
                        <h5 style={{ textAlign: 'left' }}>BASEL II:</h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>We are able to guide our clients on matters relating to Basel II framework using basic, standard and advanced approaches for Market, Credit and Operational risk.</li>
                            <li style={{ marginBottom: "7px" }}>Pillar II (ICAAP) is another significant piece in the risk management framework that really establishes deep foundation for strong risk governance. Along with ICAAP we hold experience on conducting stress testing for the defined risks.</li>
                            <li style={{ marginBottom: "7px" }}>Post ICAAP implementation exercise i.e. ICAAP validation is also serviced by us.</li>
                          
                        </ul>
                        <h5 style={{ textAlign: 'left' }}>BASEL III:</h5>
                        <ul style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>Just like any other regulation, Basel III evolved as a result of a crisis, a regulatory arbitrage or a crack in the Basel II regulation that resulted in the massive global crisis.</li>
                            <li style={{ marginBottom: "7px" }}>As per BIS -"Basel III" is a comprehensive set of reform measures, developed by the Basel Committee on Banking Supervision (BCBS), to strengthen the regulation, supervision and risk management of the banking sector.</li>
                            <li style={{ marginBottom: "7px" }}>These measures aim to:
                            <ol style={{ textAlign: 'left' }}>
                            <li style={{ marginBottom: "7px" }}>improve the banking sector's ability to absorb shocks arising from financial and economic stress, whatever the source.</li>
                            <li style={{ marginBottom: "7px" }}>improve risk management and governance.</li>
                            <li style={{ marginBottom: "7px" }}>strengthen banks' transparency and disclosures.</li>
                            </ol>
                            </li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}