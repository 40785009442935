import React from 'react';
import ServiceCards from './ServiceCards';
import Carousel from 'react-bootstrap/Carousel'
import image2 from './images/carousel/image2.jpg';
import image3 from './images/carousel/image3.jpg';
import image4 from './images/carousel/image4.jpg';
import { Link } from 'react-router-dom';

export default function Home() {

    const handleClick =()=> {
        var element = document.getElementById("service-card-id");
        element.scrollIntoView({behavior:"smooth"});
    }
   
        return (
            <div>
                <div className='container' id="myCarousel">
                    <Carousel variant="dark" style={{ 'boxShadow': '0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,.22)' }}>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={image3}
                                alt="First slide"
                            />
                            <Carousel.Caption>
                            <h5>Our Team</h5>
                                <p>To know more about our team click on the button below.</p>
                                <button className="btn btn-primary" ><Link to='/team'style={{color:'white'}}>Know More</Link></button>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={image2}
                                alt="Second slide"
                            />
                            <Carousel.Caption>
                            <h5>About SAAAS</h5>
                                <p>SAAAS is an ideation of childhood friends who have a vision to provide business solutions across various industries in the areas of Process, Taxation and Information Technology.</p>
                                <button className="btn btn-primary" ><Link to='/about'style={{color:'white'}}>Know More</Link></button>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={image4}
                                alt="Fourth slide"
                            />
                            <Carousel.Caption>
                                <h5>Areas of Expertise</h5>
                               <p>We provide the best services in multiple areas</p>
                                <button className="btn btn-primary" onClick={handleClick}>Know More</button>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>



                <div className='container' style={{ marginTop: '40px' }}>
                    <div className='col-md-12'>
                        <h1 className="text-center" style={{ 'color': '#1D2951' }}> About SAAAS</h1>
                        <p className="text-center">SAAAS is an ideation of childhood friends who have a vision to provide business solutions across various industries in the areas of Process, Taxation and Information Technology. SAAAS & Co is a India based firm which is highly concerned with the quality of services, approach, value, commitment, and behaviour towards fulfilment of client’s objective. With a strong belief in individuality of each client, we always endeavour to provide personalized professional services with commitment, while adhering to the best of solutions and practices with utmost dynamism and with use of advanced technology. Our solution driven approach with our large team / network of professionals has helped us serve entities from start-ups to large corporates in India and International.</p>
                </div>



                </div>
                <ServiceCards></ServiceCards>
                <div style={{ marginTop: '100px' }}></div>
            </div>)
      }
