import React from 'react';
import { HiMail } from 'react-icons/hi';
import { BsTelephoneFill } from 'react-icons/bs';


class Shravan extends React.Component {
    render() {
        return (<div className='container team-profile'>
            <div className='row'>
                <div className='col-md-12 profile-details'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <h1 className='profile-name'>Shravan Gehlot</h1>
                            <h3 style={{ textAlign: "center",color:'white' }}>Head | Indirect Taxation</h3>
                            <div className='profile-contact'>
                                <p><HiMail></HiMail> - shravan@saaasllp.com</p>
                                <p><BsTelephoneFill></BsTelephoneFill> - +91 – 9500144702</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row profile-box justify-content-center'>
                <div className='col-md-9'>
                <h2 className='profile-heading'></h2>
                <div className='profile-description'>
                    <ul>
                    <li>He has experience in handling Indirect tax matters focusing on GST, SEZ
                        and Foreign trade policies, Refunds, Audit assignments and consultancy.</li>
                    <li>He has advised clients in setting up of SEZ units and providing assistance
                        in obtaining various FTP related schemes. He also has handful experience
                        of working in management consulting industry. </li>
                    <li>He is well equipped in handling process and risk based internal Audits for
                        various sectors including but not limited to Construction, Auto mobile
                        OEM’s, Digital transformation services etc and have experience in handling
                        Internal Audits for various Industries.</li>
                    <li>He has co-authored booklet on “Practical FAQ on GST” and also reviewed
                        “Handbook on Advance ruling” published by Indirect Tax Committee of
                        ICAI.
                    </li>
                    <li>He is actively involved in taking various sessions on GST to Revenue
                        department officials and professional firms and collaborates with other
                        professional colleagues to update them regularly on GST related matters.</li>
                    </ul>
                </div>
                </div>
            </div>
        </div>)
    }
}
export default Shravan;