import React from 'react';
import { HiMail } from 'react-icons/hi';
import { BsTelephoneFill } from 'react-icons/bs';


class Shahill extends React.Component {
    render() {
        return (<div className='container team-profile'>
            <div className='row'>
                <div className='col-md-12 profile-details'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <h1 className='profile-name'>Shahill Kumar</h1>
                            <h3 style={{ textAlign: "center",color:'white' }}>Head | Audit and Assurance</h3>
                            <div className='profile-contact'>
                                <p><HiMail></HiMail> - shahill@saaasllp.com</p>
                                <p><BsTelephoneFill></BsTelephoneFill> - +91 - 9677250403</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row profile-box justify-content-center'>
                <div className='col-md-9'>
                    <h2 className='profile-heading'></h2>
                    <div className='profile-description'>
                        <ul>
                            <li>Shahill Kumar was associated with a Chennai based audit firm. He is
                                experienced in various Audit & Assurance services of banking industry. </li>
                            <li>He has assisted in carrying out the regulatory Central Statutory audit of
                                Public sector bank.</li>
                            <li>He is also well versed with Internal audit of companies from various
                                industries and have concluded valuation of various companies.
                            </li>
                            <li>He was a Team lead for various Regulatory Depository Participant audit
                                (NSE,BSE & CDSL), Digital Payment audit, Asset Recovery management
                                audit, Information Systems Audit, RA Audit, Internal Audit & GST audits.</li>
                            <li>He has tapped various Industries like Pharma, Banking, Jewellery, Steel,
                                Agriculture, Stock exchange, Information Technology etc.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default Shahill;