import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { RiCustomerService2Fill } from 'react-icons/ri';
import { FaEnvelope } from 'react-icons/fa';
import { IoCall } from 'react-icons/io5';

class ContactUs extends React.Component {

    render(){
        return(<div className='container'>
               <div className='row justify-content-md-center'>
                <div className='col-md-3 '>
                    <div className='card contactUs'>
                        <div className='card-header contactUs-header'>
                            <FaMapMarkerAlt style={{width:'65px',height:'65px'}}></FaMapMarkerAlt>
                        </div>
                        <div className='card-body justify-content-md-center' style={{marginTop:'20px',fontSize:'20px',color:'#1D2951'}}>
                            <p>Hardevi Chambers,</p> 
                            <p>Office No: 75,</p>
                            <p>2nd Floor, Pantheon Rd, Egmore, </p>
                            <p> Chennai - 600008, India</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 '>
                    <div className='card contactUs'>
                        <div className='card-header contactUs-header'>
                            <RiCustomerService2Fill style={{width:'65px',height:'65px'}}></RiCustomerService2Fill>
                        </div>
                        <div className='card-body justify-content-md-center' style={{marginTop:'32px',fontSize:'20px',height:'200px',color:'#1D2951'}}>
                        <p><IoCall size={20}/><span> (+91) 044 47430951</span></p>
                        <p><FaEnvelope size={20}/><span> office@saaasllp.com</span></p>
                        </div>
                    </div>
                </div>
               </div>
        </div>);
    }
}

export default ContactUs;